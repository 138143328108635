<template>
  <div class="page oauth-page">
    <div class="container-padding">
      <div class="oauth-page__inner">
        <div class="oauth-page__loading">
          <span>Авторизация...</span>
          <IconComponent name="loading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import { confirmUser } from "@/utils";

export default {
  components: { IconComponent },
  async mounted() {
    this.goHome();
    // await this.$store.dispatch("USER", {
    //   apollo: this.$apollo.provider.clients,
    // });
    // let token = this.$route.query.token;
    // if (token) {
    //   this.$store.state.auth_token = token;
    //   await this.confirmUser({
    //     store: this.$store,
    //     router: this.$router,
    //     apollo: this.$apollo.provider.clients,
    //     data: { token: this.$route.query.token },
    //   });
    // } else {
    //   this.goHome();
    // }
  },
  methods: {
    confirmUser,
    goHome() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style lang="stylus">
.oauth-page {
  display flex
  flex-direction column
  align-items center
  justify-content center
  gap 16px

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;

    .icon {
      width 20px
      height 20px
    }
  }
}
</style>
